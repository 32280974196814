<template>
  <div>
    <carousel :list="banner" :pagination="false"/>
    <!--产业布局-->
    <div class="industry">
      <div class="titleCoat">
        <div class="title">
          <p class="titleLine"/>
          <p class="titleValue">产业布局</p>
          <p class="titleLine"/>
        </div>
        <p class="subtitle">INDUSTRY SERVICE</p>
      </div>
      <div class="container-fluid">
        <div class="row" v-if="industry && industry.length>0">
          <p class="offset-lg-1"/>
          <router-link class="industryItemCoat col-6 col-lg-2 col-md-4"
                       v-for="(item,index) in industry"
                       :to="{
                         path:'/industrial/',
                         query:{
                           index,
                           category_id:item.category_id
                         },
          }" tag="div">
            <div class="industryItem">
              <div class="industryBasic">
                <img class="industryBasicIcon" :src="item.image"/>
                <p class="industryBasicTitle">{{item.name}}</p>
                <p class="industryBasicValue">{{item.description}}</p>
              </div>
              <div class="industryIntro">
                <p class="industryIntroTitle">{{item.title}}</p>
                <p class="industryIntroValue">{{item.description}}</p>
                <p class="industryIntroBut">MORE →</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <!--行业应用-->
    <div class="app">
      <div class="titleCoat">
        <div class="title">
          <p class="titleLine"/>
          <p class="titleValue">产业应用</p>
          <p class="titleLine"/>
        </div>
        <p class="subtitle">INDUSTRY SERVICE</p>
      </div>
      <!--PC端-->
      <div class="appList d-none d-lg-block" v-if="industryApp && industryApp.length>0">
        <div class="appItemCoat"
             v-for="(item,index) in industryApp"
             v-if="index<10"
             :style="industryAppStyle(index)"
             @click="goAppItem(index,item.path)"
             @mousemove="setActiveIndustryApp(index)">
          <div class="activeIndustry"/>
          <div class="appItem">
            <img class="appItemImg" :src="item.image"/>
            <div class="appItemInfo">
              <p class="appItemTitle" :class="{activeAppItemTitle:activeIndustryApp==index}">{{item.name}}</p>
              <p class="appItemValue" v-show="activeIndustryApp==index">{{item.description}}</p>
            </div>
          </div>
        </div>
      </div>
      <!--手机端-->
      <swiper class="wapAppList d-block d-lg-none" :options="swiperOptions" v-if="industryApp && industryApp.length>0">
        <swiper-slide class="wapAppItem" v-for="(item,index) in industryApp">
          <div @click="goAppItem(index,item.path)">
            <img class="wapAppItemImg" :src="item.image"/>
            <p class="wapAppItemTitle">{{item.name}}</p>
            <p class="wapAppItemValue">{{item.description}}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <!--关于我们-->
    <div class="about">
      <div class="titleCoat">
        <div class="title">
          <p class="titleLine"/>
          <p class="titleValue">关于我们</p>
          <p class="titleLine"/>
        </div>
        <p class="subtitle">ABOUT US</p>
      </div>
      <div class="container-fluid">
        <div class="row homeAboutRow">
          <img class="homeAboutBGI d-lg-block d-none" src="~assets/images/img/homeAboutBGI.png"/>
          <div class="col-lg-4 col-12 homeAboutImg">
            <img v-if="about" :src="about.image"/>
          </div>
          <div class="col-lg-5 col-12 homeAboutInfoCoat">
            <div class="homeAboutInfo" v-if="about">
              <p class="homeAboutTitle">{{about.name}}</p>
              <p class="homeAboutValue" v-html="about.description"></p>
              <router-link :to="{
                path:'/about',
                query:{
                  index:0
                }
              }" tag="p" class="homeAboutBut">MORE →
              </router-link>
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="row">
              <router-link :to="{
                path:'/about',
                query:{
                  index:1
                }
              }" tag="div" class="col-lg-12 col-6 homeAboutBox">
                <img class="homeAboutBoxImg " src="~assets/images/img/homeAboutImg2.jpg">
                <div class="homeAboutBoxInfo">
                  <p class="homeAboutBoxCText">企业文化</p>
                  <p class="homeAboutBoxEText">CUL TURE</p>
                </div>
              </router-link>
              <router-link :to="{
                path:'/about',
                query:{
                  index:2
                }
              }" tag="div" class="col-lg-12 col-6 homeAboutBox">
                <img class="homeAboutBoxImg" src="~assets/images/img/homeAboutImg3.jpg">
                <div class="homeAboutBoxInfo">
                  <p class="homeAboutBoxCText">荣誉资质</p>
                  <p class="homeAboutBoxEText">HONONR</p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--新闻资讯-->
    <div class="news container-fluid">
      <div class="titleCoat">
        <div class="title">
          <p class="titleLine"/>
          <p class="titleValue">新闻中心</p>
          <p class="titleLine"/>
        </div>
        <p class="subtitle">NEWS</p>
      </div>
      <div class="row newsBox">
        <router-link :to="{path:'/newsdetail/'+topNews.news_id,query:{index:0}}" tag="div"
                     class="newsLeftCoat col-lg-5 col-12" v-if="topNews && topNews.length>0">
          <div class="newsLeft">
            <div class="newsLeftImgCoat">
              <img class="newsLeftImg" :src="topNews.image"/>
            </div>
            <div class="newsLeftInfo">
              <p class="newsLeftTitle">{{topNews.title}}</p>
              <div class="newsLeftBottom">
                <p class="newsLeftDate">{{topNews.date_added}}</p>
                <p class="newsBut">MORE →</p>
              </div>
            </div>
          </div>
        </router-link>
        <div class="newsRight col-12 col-lg-7" v-if="newsList">
          <router-link :to="{path:'/newsdetail/'+item.news_id,query:{index:0}}" tag="div" class="newsRightBox"
                       v-for="item in newsList">
            <div class="newsRightImgCoat"><img class="newsRightImg" :src="item.image"/></div>
            <div class="newsRightInfo">
              <div>
                <p class="newsRightTitle">{{item.title}}</p>
                <p class="newsRightValue" v-html="item.description"></p>
              </div>
              <div class="newsLeftBottom">
                <p class="newsLeftDate">{{item.date_added}}</p>
                <p class="newsBut">MORE →</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <router-link :to="{path:'/news',query:{index:0}}" tag="div" class="newsButCoat"><p class="newsBut">MORE →</p>
      </router-link>
    </div>
  </div>
</template>
<script>
  import Carousel from "../../components/common/swiper/Carousel";
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'
  import swiper, {Autoplay, Pagination, EffectFade, Navigation} from 'swiper';
  /**接口*/
  import {getHomeData, getHomeAbout} from 'network/home';
  /**接口 END*/

  swiper.use([Autoplay, Pagination, EffectFade, Navigation]);

  export default {
    name: "Home",
    data() {
      return {
        //变量
        activeIndustryApp: 0,
        swiperOptions: {
          autoplay: true,
          slidesPerView: 'auto',
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
        },
        //数据
        banner: [],
        about: null,
        industry: null,

        //产页应用
        industryApp: null,
        //左边的一篇新闻
        topNews: null,
        //右边新闻的列表
        newsList: null,
      }
    },
    methods: {
      //带参数跳转到产业应用页面
      goAppItem(index, path) {
        this.$router.push({
          path: '/industryapp',
          query: {
            index,
            category_id: path
          },
        })
      },
      /*控制产业应用块的样式*/
      industryAppStyle(index) {
        let num = 8.2;
        if (index == this.activeIndustryApp) {
          return 'left:' + (index * num) + '%;transition: left .3s;z-index:' + index + 1;
        } else if (index > this.activeIndustryApp) {
          //27为单独一个item的宽度 值为27%
          return 'left:' + (((index - 1) * num) + 27) + '%;transition: left .3s;z-index:' + index + 1;
        } else if (index < this.activeIndustryApp) {
          return 'left:' + (index * num) + '%;transition: left .3s;z-index:' + index + 1;
        }
      },
      setActiveIndustryApp(index) {
        this.activeIndustryApp = index;
      },
    },
    components: {Carousel, Swiper, SwiperSlide},
    created() {
      //首页所有数据
      getHomeData().then(res => {
        let data = res.data;
        this.banner = data.banners1;
        this.industryApp = data.categories_2;
        this.industry = data.categories_1;
        this.about = data.information[0]
        this.topNews = data.news1[0];
        this.newsList = data.allnews;
      });
    }
  }
</script>
<style scoped>

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 992px) {
    .newsLeftCoat {
      padding-right: .6rem;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (max-width: 992px) {
    .newsLeftCoat {
      padding-right: 0;
      margin-bottom: .6rem;
    }
  }
</style>
<style>
  .homeAboutValue img{
    display: none;
  }
</style>
<style scoped>
  /*新闻中心*/
  .newsButCoat {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;
  }

  .newsRight {
    height: 12.25rem;
  }

  .newsRightBox {
    display: flex;
    height: 49%;
  }

  .newsRightBox:hover .newsRightInfo, .newsLeftCoat:hover .newsLeftInfo {
    background-color: var(--red);
    transition: background-color .3s;
  }

  .newsRightBox:hover .newsRightTitle, .newsRightBox:hover .newsRightValue, .newsLeftCoat:hover .newsLeftTitle, .newsLeftCoat:hover .newsLeftDate {
    color: #fff;
    transition: color .3s;
  }

  .newsRightBox:hover .newsLeftDate {
    color: #fff;
    transition: color .3s;
  }

  .newsRightBox:hover .newsBut, .newsLeftCoat:hover .newsBut {
    border-color: #fff;
    background-color: #ffffff;
    transition: background-color, border-color .3s;
  }

  .newsRightBox:nth-child(1) {
    margin-bottom: 1.5%;
  }

  .newsRightImgCoat {
    height: 100%;
    width: 4.5rem;
    overflow: hidden;
  }

  .newsRightImgCoat:hover .newsRightImg {
    transform: scale(1.2, 1.2);
    transition: transform .6s;
  }

  .newsRightImg {
    height: 100%;
    min-width: 100%;
    transition: all .6s linear;
  }

  .newsRightInfo {
    flex: 1;
    background-color: #f5f5f5;
    padding: .6rem 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .newsRightTitle {
    font-size: .4rem;
    color: #595959;
    margin-bottom: .35rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 1; /* 控制最多显示几行 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  }

  .newsRightValue {
    font-size: .35rem;
    color: #595959;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 2; /* 控制最多显示几行 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  }

  .news {
    margin-bottom: 1.5rem;
  }

  .newsBox {
    padding: 0 var(--marginX);
  }

  .newsLeftCoat {
    height: 12.25rem;
  }

  .newsLeft {
    width: 100%;
    height: 100%;
  }

  .newsLeftImgCoat {
    width: 100%;
    height: 9.25rem;
    overflow: hidden;
    cursor: pointer;
  }

  .newsLeftImgCoat:hover .newsLeftImg {
    transform: scale(1.2, 1.2);
    transition: transform .6s;
  }

  .newsLeftImg {
    min-width: 100%;
    height: 100%;
    transition: all .6s linear;
  }

  .newsLeftInfo {
    width: 100%;
    height: 3rem;
    padding: .3rem .5rem .5rem .5rem;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .newsLeftTitle {
    font-size: .4rem;
    color: #4c4c4c;
    margin-bottom: .4rem;
  }

  .newsLeftBottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .newsLeftDate {
    font-size: .35rem;
    color: #737373;
  }

  .newsBut {
    display: block;
    padding: .1rem .8rem;
    border-radius: 30px;
    border: 1px solid var(--red);
    font-size: .4rem;
    color: var(--red);
    cursor: pointer;
  }

  /*新闻中心 END*/
</style>
<style scoped>
  .appList {
    position: relative;
    display: flex;
    align-items: center;
    height: 11rem;
    overflow: hidden;
  }

  .appItemCoat {
    width: 28%;
    height: 100%;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  .activeIndustry {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, .1);
    transition: background-color .3s;
  }

  .appItemCoat:hover .activeIndustry {
    background: none;
    transition: background-color .3s;
  }

  .appItem {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .appItemImg {
    height: 100%;
    width: 100%;
  }

  .appItemInfo {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .appItemTitle {
    position: absolute;
    top: 0;
    left: 0;
    writing-mode: vertical-rl;
    padding: .5rem;
    font-size: .45rem;
    color: #fff;
  }

  .activeAppItemTitle {
    background-color: var(--red);
    transition: background-color .3s;
  }

  .appItemValue {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 2; /* 控制最多显示几行 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    padding: .3rem .75rem .1rem .75rem;
    font-size: .4rem;
    color: #fff;
    background-color: rgba(0, 0, 0, .3);
  }

  .wapAppItem {
    width: 11rem;
    height: 10rem;
    position: relative;
  }

  .wapAppItemImg {
    width: 100%;
    height: 100%;
  }

  .wapAppItemTitle {
    position: absolute;
    padding: .3rem;
    font-size: .35rem;
    color: #fff;
    top: 0;
    left: 0;
    background-color: var(--red);
    writing-mode: vertical-rl;
  }

  .wapAppItemValue {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: .3rem .4rem 2px .4rem;
    background-color: rgba(0, 0, 0, .3);
    font-size: .35rem;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 2; /* 控制最多显示几行 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  }

  .industry {
    padding: 0 var(--marginX);
  }

  .industryItemCoat {
    padding: 0 .2rem;
    margin-bottom: .6rem;
    height: 6.95rem;
  }

  .industryItem {
    height: 100%;
    position: relative;
  }

  .industryItem:hover .industryIntro {
    display: block;
    transition: opacity .3s;
  }

  .industryBasic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .industryBasicIcon {
    width: 3rem;
    height: auto;
    margin-bottom: .55rem;
  }

  .industryBasicTitle {
    font-size: .45rem;
    color: #a6a6a6;
    margin-bottom: .45rem;
  }

  .industryBasicValue {
    font-size: .35rem;
    color: #a6a6a6;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
    -webkit-line-clamp: 2; /* 控制最多显示几行 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  }

  .industryIntro {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    padding: .9rem .5rem;
    background-color: var(--red);
    z-index: 2;
    display: none;
    cursor: pointer;
  }

  .industryIntroTitle {
    text-align: center;
    font-size: .45rem;
    margin-bottom: .25rem;
    color: #fff;
  }

  .industryIntroValue {
    font-size: .35rem;
    color: #fff;
    margin-bottom: .55rem;
    height: 2.9rem;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .industryIntroBut {
    flex: 1;
    margin: .6rem .1rem 0 .1rem;
    padding: .2rem 0;
    background-color: #fff;
    border-radius: 30px;
    font-size: .4rem;
    color: var(--red);
    text-align: center;
  }

  .titleCoat {
    padding: 1.4rem 0;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .3rem;
  }

  .titleLine {
    width: 1rem;
    height: 1px;
    border-top: 1px solid var(--red);
  }

  .titleValue {
    font-size: .65rem;
    color: #4c4c4c;
    margin: 0 .4rem;
  }

  .subtitle {
    text-align: center;
    font-size: .35rem;
    color: #a6a6a6;
  }

  /*  关于我们*/
  .about {
    background-color: #f5f5f5;
    padding-bottom: .6rem;
  }

  .homeAboutRow {
    padding: 0 var(--marginX);
    position: relative;
    display: flex;
    align-items: center;
  }

  .homeAboutBGI {
    position: absolute;
    height: auto;
    width: 12rem;
  }

  .homeAboutImg {
    padding: 0 5px;
    width: 100%;
    height: 8rem;
    overflow: hidden;
  }

  .homeAboutImg:hover img {
    transform: scale(1.2, 1.2);
    transition: transform .6s;
  }

  .homeAboutImg img {
    width: 100%;
    height: auto;
    transition: all .6s linear;
  }

  .homeAboutInfoCoat {
    padding: 0 2px;
    margin-bottom: .6rem;
  }

  .homeAboutInfo {
    padding: .55rem .6rem;
    background-color: #fff;
  }

  .homeAboutTitle {
    font-size: .45rem;
    color: #4c4c4c;
    margin-bottom: .6rem;
  }

  .homeAboutValue {
    font-size: .4rem;
    color: #8c8c8c;
    margin-bottom: 1.7rem;
  }

  .homeAboutBut {
    display: inline-block;
    padding: .2rem .8rem;
    border: 2px solid var(--red);
    font-size: .4rem;
    color: var(--red);
    border-radius: 30px;
    position: relative;
    bottom: 0;
    cursor: pointer;
    transition: all .1s linear;
  }

  .homeAboutBut:hover {
    bottom: 3px;
    transition: bottom, box-shadow .1s;
  }

  .homeAboutBox {
    width: 100%;
    position: relative;
    margin-bottom: .5rem;
    padding: 0 2%;
    cursor: pointer;
  }

  .homeAboutBoxImg {
    width: 100%;
    height: auto;
  }

  .homeAboutBoxInfo {
    position: absolute;
    bottom: 1.5rem;
    padding: 0 .75rem;

  }

  .homeAboutBoxCText {
    font-size: .5rem;
    color: #fff;
    margin-bottom: .25rem;
  }

  .homeAboutBoxEText {
    font-size: .4rem;
    color: #fff;
  }

  /*  关于我们 END*/
</style>