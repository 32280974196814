<template>
  <div v-if="list && list.length>0" class="carousel">
    <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
      <swiper-slide class="swiperChild"
                    v-for="item in list">
        <img :src="item.image" alt="#"/>
      </swiper-slide>
      <div v-if="pagination" class="swiper-pagination" slot="pagination"></div>
    </swiper>
<!--    <div class="carouselClick">-->
      <img class="carouselClickPre" @click="carouselClick('pre')" src="~assets/images/icon/carouselPre.png" alt="#"/>
      <img class="carouselClickNext" @click="carouselClick('next')" src="~assets/images/icon/carouselNext.png" alt="#"/>
<!--    </div>-->
  </div>
</template>

<script>
  import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'
  import swiper, {Autoplay, Pagination,EffectFade,Navigation} from 'swiper';

  swiper.use([Autoplay, Pagination,EffectFade,Navigation]);
  export default {
    name: "Carousel",
    props: {
      list: {
        type: Array,
        default() {
          return null
        }
      },
      pagination: {
        type: Boolean,
        default: true
      },
    },
    components: {
      Swiper, SwiperSlide
    },
    data() {
      return {
        //配置
        swiperOptions: {
          // effect : 'fade',
          autoplay: true,
          loop:true,
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
          bulletClass: 'my-bullet',
          pagination: {
            el: '.swiper-pagination',
            bulletClass: 'my-bullet',
            bulletActiveClass: 'my-bullet-active'
          },
        },
      }
    },
    methods:{
      carouselClick(type){
        let activeIndex=this.$refs.mySwiper.$el.swiper.activeIndex;
       if (type=='pre'){
         this.$refs.mySwiper.$el.swiper.slideTo(activeIndex-1,300,false);
       }else if (type=='next'){
         this.$refs.mySwiper.$el.swiper.slideTo(activeIndex+1,300,false);
       }
      }
    },
    mounted() {
     if (this.list && this.list.length>0){
       // this.$refs.mySwiper.$swiper.autoplay.start();
       this.swiperOptions = {
         autoplay: true,
         observer: true,//修改swiper自己或子元素时，自动初始化swiper
         observeParents: true,//修改swiper的父元素时，自动初始化swiper
         bulletClass: 'my-bullet',
         pagination: {
           el: '.swiper-pagination',
           bulletClass: 'my-bullet',
           bulletActiveClass: 'my-bullet-active'
         }
       };
       this.$emit('getCarouselInfo', {
         //以加载的图片个数
         //当前轮播图的活跃下标
         activeIndex: this.$refs.mySwiper.$el.swiper.activeIndex,
       });
     }
    },
  }
</script>

<style scoped>
  .swiper-container {
    --swiper-pagination-color: #221815;
  }
  .swiperChild img {
    width: 100%;
    height: auto;
    border-radius: 6px;
  }
</style>
<style>
  .carousel{
    position: relative;
  }
  .carouselClickPre{
    width: 1.2rem;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 46%;
    left: 15%;
    cursor: pointer;
  }
  .carouselClickNext{
    width: 1.2rem;
    height: auto;
    position: absolute;
    z-index: 3;
    top: 46%;
    right: 15%;
    cursor: pointer;
  }
  .swiper-pagination {
    padding-right: 6%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: .8rem;
  }

  .my-bullet {
    border-radius: 50%;
    width: .25rem;
    height: .25rem;
    margin: 0 .2rem;
    display: inline-block;
    background-color: #fff;
  }

  .my-bullet-active {
    border-radius: 50%;
    width: .25rem;
    height: .25rem;
    margin: 0 .2rem;
    display: inline-block;
    background-color: #221815;
  }
</style>