import {request} from "./request";
export function getHomeData() {
  return  request({
    params:{
      route:'common/home/homesql'
    }
  })
};
export function getHomeAbout() {
  return request({
    url:'information/information/getAboutMe',
    params:{
      route:'information/information/getAboutMe',
      information_id:1
    },
  })
};
